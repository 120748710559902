import { Component, Vue } from 'vue-property-decorator';

import Arrow from '@/assets/icons/arrow-right.svg?inline';
import Eye from '@/assets/icons/lindblad-eye.svg?inline';

import { getComponentProps } from '@/utils/vueUtils';
import { PromoRibbonBlockViewModel } from './PromoRibbonModule.types';
import { stripHtml } from '@/utils/stringUtils';

const PromoRibbonModuleProps = Vue.extend({
  props: getComponentProps<PromoRibbonBlockViewModel>({
    Tag: 'Marketing',
    BackgroundImage: {
      Url: '',
      Alt: ''
    },
    Title: 'Get our brochure for the Galapagos Islands expedition',
    Link: 'https://world.stg2019.expeditions.com/destinations/antarctica/',
    Image: {
      Url: 'https://stg2019.expeditions.com/globalassets/brochure_cover_imgs/415x536/farther-202124-wd.jpg',
      Alt: 'Galapagos Islands'
    },
    Target: "_self"
  }),
});

@Component({
  components: {
    Arrow,
    Eye
  },
})
export default class PromoRibbonModule extends PromoRibbonModuleProps {
  get imageAlt() {
    return this.p.Image.Alt || stripHtml(this.p.Title)
  }
  get isTitleLong() {
    return stripHtml(this.p.Title).length > 60
  }
}
